import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import ltDefermentApolloClient from '@/lib/appsync/ltDeferment';
import defermentApolloClient from '@/lib/appsync/deferment';
import getQueryLabelsPred from '@/graphql/deferment/queries/getQueryLabelsPred.graphql';
import runDefermentCalc from '@/graphql/deferment/queries/runDefermentCalc.graphql';
import updatePrediction from '@/graphql/deferment/mutations/updatePrediction.graphql';

import getFit from '@/graphql/deferment/queries/getFit.graphql';
import saveFit from '@/graphql/deferment/queries/saveFit.graphql';

import tasqsListModule from '@/store/modules/tasqsListModule';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { DateTime } from 'luxon';
import accountModule from '@/store/modules/accountModule';
import isOnline from 'is-online';
import localForage from '@/lib/localForage';


@Module({
  dynamic: true,
  namespaced: true,
  name: 'defermentLabelingModule',
  store,
})
class DefermentLabelingModule extends VuexModule {
  chartLabelingData: any = [];

  newChartLabelingData: any = null;

  datasetForPrediction: any = null;

  defermentModelCalculatingETA: any = null;

  defermentModelCalculatingID: any = null;

  fetchingDefermentLabelPrediction: boolean = false;

  ModelUUID: any = null;


  @Mutation
  setModelUUID(data): void {
    this.ModelUUID = data
  }


  @Mutation
  setDefermentModelCalculatingValues(data): void {
    this.defermentModelCalculatingETA = data.ETA;
    this.defermentModelCalculatingID = data.id;
  }

  @Mutation
  setChartLabelingData(data): void {
    this.chartLabelingData = data;
  }

  @Mutation
  setNewChartLabelingData(data): void {
    this.newChartLabelingData = data;
  }

  @Mutation
  setFetchingDefermentLabelPrediction(data): void {
    this.fetchingDefermentLabelPrediction = data;
  }

  @Mutation
  setDatasetForPrediction(data): void {
    if (Array.isArray(data) && !data.length) {
      this.datasetForPrediction = null;
      return;
    }
    this.datasetForPrediction = data;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  @Action
  async getFitRun({
    data,
    nodeid,
    value_type,
    mask
  }): Promise<void> {
    this.setFetchingDefermentLabelPrediction(true)
    try {
    var operator = getConfigEnv('OPERATOR_LOWERCASED')
    if (getConfigEnv('OPERATOR_LOWERCASED') == "civitas") {
      operator = "Extraction"
    } else if (getConfigEnv('OPERATOR_LOWERCASED') == "swn") {
      operator = "SWN"
    }
      const {
        data: {
          getFit: {
            ModelUUID,
            Rows
          },
        },
      } : any = await ltDefermentApolloClient.query({
        query: getFit,
        variables: {
        data: data,
        nodeid: nodeid,
        operator: operator,
        value_type: value_type,
        mask: mask
      },
      fetchPolicy: 'network-only'
      });
    this.setModelUUID(ModelUUID)

    if (!(Rows === undefined || Rows.length == 0)) {
      this.setNewChartLabelingData(Rows);
    }

    this.setFetchingDefermentLabelPrediction(false)
    return ModelUUID;
    } catch (err) {
      console.log('Error: ', err);
    }
  }

  @Action
  async saveFitRun({
  ModelUUID,
  }): Promise<void> {

    const {
      data: {
        saveFit: {
          StatusCode,
        },
      },
    } : any = await ltDefermentApolloClient.query({
    query: saveFit,
      variables: {
        ModelUUID: ModelUUID,
      },
    });
  }


  @Action
  async saveFitRunAndRemoveTarget({
  ModelUUID,
  nodeID,
  valueType,
  nullValue,
  }): Promise<void> {

    const {
      data: {
        saveFit: {
          StatusCode,
        },
      },
    } : any = await ltDefermentApolloClient.query({
    query: saveFit,
      variables: {
        ...((ModelUUID.length) && { ModelUUID }),
        nodeid: nodeID,
        value_type: valueType,
        Null: nullValue,
        operator: getConfigEnv('OPERATOR_PASCALED'),
      },
    });
  }

  @Action
  async getChartReLabelingData(data: any = 365): Promise<void> {
    // Reset update prediction data


      try {

    let wellName = tasqsListModule.activeTasq?.wellName;
    let signalChartTime = data;

    signalChartTime = data
    this.setDatasetForPrediction(null);
    if (!wellName) {
      return;
    }

    let result: any = {};

      const daysAgo = tasqsListModule.signalChartTime || signalChartTime;
      const {
        data: {
          query_labels_pred: {
            dataset,
            deferment_count: defermentCount,
            deferment_total: defermentTotal,
            total_count: totalCount,
          },
        },
      } : any = await defermentApolloClient.query({
        query: getQueryLabelsPred,
        variables: {
          nodeid: wellName,
          type: 'labels',
          operator: getConfigEnv('OPERATOR_PASCALED'),
          from_date: DateTime.utc().minus({ days: data + tasqsListModule.fromNowBack  }).toISO().slice(0, -5),
          to_date: DateTime.utc().minus({days: tasqsListModule.fromNowBack}).toISO().slice(0, -5),
        },
      });

     if(tasqsListModule.enablePreloading) {
       await localForage.setItem(`getChartLabelingData-${wellName}`, dataset);
      }
      result = jsonParse(dataset);



      this.setChartLabelingData({
        dataset: result,
      });
      }catch(error){
          console.log(error.message);
      }

  }

  @Action
  async getChartLabelingData(data: any = 90): Promise<void> {
    // Reset update prediction data
    let isOfflinePreloading = false;


    let wellName = tasqsListModule.activeTasq?.wellName;
    let signalChartTime = 90;

    if(typeof data === 'object') {
      wellName = data.wellName;
      signalChartTime = data.signalChartTime;
      isOfflinePreloading = true;
    } else {
      signalChartTime = data
      this.setDatasetForPrediction(null);
    }
    if (!wellName) {
      return;
    }

    let result: any = {};

    if (await isOnline()) {
      const daysAgo = tasqsListModule.signalChartTime || signalChartTime;
      const {
        data: {
          query_labels_pred: {
            dataset,
            deferment_count: defermentCount,
            deferment_total: defermentTotal,
            total_count: totalCount,
          },
        },
      } : any = await defermentApolloClient.query({
        query: getQueryLabelsPred,
        variables: {
          nodeid: wellName,
          type: 'labels',
          operator: getConfigEnv('OPERATOR_PASCALED'),
          from_date: DateTime.utc().minus({ days: daysAgo + tasqsListModule.fromNowBack  }).toISO().slice(0, -5),
          to_date: DateTime.utc().minus({days: tasqsListModule.fromNowBack}).toISO().slice(0, -5),
        },
      });

      if(tasqsListModule.enablePreloading) {
        await localForage.setItem(`getChartLabelingData-${wellName}`, dataset);
      }

      result = jsonParse(dataset);
    } else {
      const cacheData: any = await localForage.getItem(`getChartLabelingData-${wellName}`);
      if (cacheData) {
        result = jsonParse(cacheData);
      }
    }

    if(!isOfflinePreloading) {
      this.setChartLabelingData({
        dataset: result,
      });
    }

  }

  @Action
  async getDefermentCalcState({
    id,
    modifiedby,
  }): Promise<void> {
    try {
      const {
        data: {
          run_deferment_calc: {
            dataset,
          },
        },
      } : any = await defermentApolloClient.query({
        query: runDefermentCalc,
        variables: {
        operator: getConfigEnv('OPERATOR_LOWERCASED').charAt(0).toUpperCase() + getConfigEnv('OPERATOR_LOWERCASED').slice(1),
        id,
        modifiedby,
        },
      });

      if (!(dataset === undefined || dataset.length == 0)) {
        this.setNewChartLabelingData(JSON.parse(dataset));
      }

      this.setDefermentModelCalculatingValues(jsonParse(dataset));
    } catch (err) {
      console.log('Error: ', err);
    }
  }

  @Action
  async runDefermentCalc({

    autoConfirm,
    mappedLabelsJson,
    dataJson,
    modifiedBy,
  }): Promise<void> {
    this.setNewChartLabelingData(null);
    const mappedLabelsJsonConverted: any[] = [];
    for (let x = 0; x < mappedLabelsJson.length; x++) {
      mappedLabelsJsonConverted.push(JSON.stringify(mappedLabelsJson[x]));
    }

    const {
      data: {
        run_deferment_calc: {
          dataset,
        },
      },
    } : any = await defermentApolloClient.query({
      query: runDefermentCalc,
      variables: {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
        new_labels_json: mappedLabelsJsonConverted,
        data_json: dataJson, // Same as well test data: dict {"nodeid":, "oil_rate":, "gas_rate":, "water_rate":, "date":}
        auto_confirm: autoConfirm,
        modifiedby: modifiedBy,
      },
    });
    this.setDefermentModelCalculatingValues(jsonParse(dataset));
    this.setDatasetForPrediction(jsonParse(dataset));
  }

  @Action
  async updatePrediction({
    mappedLabels,
  }): Promise<void> {
    const newPredictionValues: any[] = [];
    for (var x = 0; x < this.newChartLabelingData.length; x++) {
      newPredictionValues.push(JSON.stringify(this.newChartLabelingData[x]));
    }

    const newLabels: any[] = [];
    for (var x = 0; x < mappedLabels.length; x++) {
      newLabels.push(JSON.stringify(mappedLabels[x]));
    }

    await defermentApolloClient.mutate({
      mutation: updatePrediction,
      variables: {
        input: {
          modifiedby: accountModule.user.email,
          new_labels: newLabels,
          new_predictions: newPredictionValues,
          nodeid: tasqsListModule.activeTasq?.wellName,
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }
}

export default getModule(DefermentLabelingModule);
